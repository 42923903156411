import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UnsubscribeComponent} from '../../../../../@core/fc-component';
import {ImportFileUploadFormSerializer} from '../../../../../@core/api.service';

export interface UploadFile {
    file: File;
    isUploaded: boolean;
    type: string;
}

export type UploadFiles = {[key: string]: UploadFile};

export interface RequiredFile extends ImportFileUploadFormSerializer {
    name: string;
    type: string;
}

@Component({
    selector: 'app-file-select-section',
    templateUrl: './file-select-section.component.html',
    styleUrls: ['../file-select.component.scss'],
})
export class FileSelectSectionComponent extends UnsubscribeComponent {
    @Input() requiredFiles: RequiredFile[];
    @Input() errors: any;
    @Input() needUploadButton = true;
    @Input() size: 'lg' | 'sm' = 'lg';

    @Output() selectFiles = new EventEmitter<UploadFiles>();
    @Output() upload = new EventEmitter<UploadFile>();

    selectedFiles: UploadFiles = {};

    onFileChanged(file: File[], type: string) {
        this.selectedFiles[type] = {file: file[0], isUploaded: false, type};
        this.selectFiles.emit(this.selectedFiles);
    }

    onUpload(type: string) {
        this.upload.emit(this.selectedFiles[type]);
    }
}
