import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
    @Input() count: number;
    @Input() pageSize: number;
    @Input() offset = 2;
    @Output() pageChanged = new EventEmitter<number>();
    @Input() currentPage = 1;
    startIndex = 0;
    endIndex = 0;
    numberOfPages: number;
    numbers: number[] = [];
    shownNumbers: number[] = [];

    constructor() {
        this.pageChanged.emit(this.currentPage);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.count || changes.pageSize) {
            this.numberOfPages = (Math.ceil(this.count / this.pageSize)) ? Math.ceil(this.count / this.pageSize) : 1;
            this.setNumbers();
            if (this.offset) {
                this.setShownIndexes();
                this.setShownNumbers();
            }
        }
    }

    setNumbers() {
        this.numbers = [];
        for (let i = 1; i <= this.numberOfPages; i++) {
            this.numbers.push(i);
        }
    }

    setCurrentPage(page) {
        if (this.currentPage != page) {
            this.currentPage = page;
            this.pageChanged.emit(this.currentPage);
            this.setShownIndexes();
            this.setShownNumbers();
        }
    }

    addPage(i) {
        if (this.numbers.includes(this.currentPage + i)) {
            this.currentPage += i;
            this.pageChanged.emit(this.currentPage);
            this.setShownIndexes();
            this.setShownNumbers();
        }
    }

    setShownIndexes() {
        this.startIndex = this.numbers.indexOf(this.currentPage) - this.offset;
        this.endIndex = this.numbers.indexOf(this.currentPage) + this.offset + 1;
        if (this.startIndex < 0) {
            this.startIndex = 0;
            const temp = this.startIndex + this.offset * 2 + 1;
            this.endIndex = (temp <= this.numbers.length) ? temp : this.numbers.length;
            return null;
        }
        if (this.endIndex > this.numbers.length) {
            this.endIndex = this.numbers.length;
            const temp = this.endIndex - this.offset * 2 - 1;
            this.startIndex = (temp < 0) ? 0 : temp;
            return null;
        }
    }

    setShownNumbers() {
        this.shownNumbers = this.numbers.slice(this.startIndex, this.endIndex);
    }

    setTo(param) {
        if (param === 'first' && this.shownNumbers[0] === this.currentPage) return;
        if (param === 'last' && this.shownNumbers[this.shownNumbers.length - 1] === this.currentPage) return;

        if (param == 'last') this.currentPage = this.numberOfPages;
        if (param == 'first') this.currentPage = 1;
        this.pageChanged.emit(this.currentPage);
        this.setShownIndexes();
        this.setShownNumbers();
    }
}
