import {Component, Input} from '@angular/core';
import {faBuilding as far} from '../../../../@theme/fortawesome/pro-regular-svg-icons';
import {faBuilding as fas} from '../../../../@theme/fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-no-facility-message',
    template: `
        <app-illustration-message [class.full-height]="fullHeight"
                                  [hasTrees]="true"
                                  [title]="'Please select a facility'"
                                  caption="This view only works when a facility is selected. Please choose one.">
        </app-illustration-message>
    `,
    animations: [],
})
export class NoFacilityMessageComponent {
    @Input() fullHeight = true;
    icon = {far, fas};
}
