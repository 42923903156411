import {Directive, Injector, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FacilityChooserService, OrganizationFacility} from '../../../../@core/facility-chooser.service';
import {FcComponent} from '../../../../@core/fc-component';
import {NoFacilityMessageComponent} from '../no-facility-message/no-facility-message.component';
import {createComponent} from '../../../../utils/component.utils';

export interface CheckFacilityOptions {
    message?: boolean;
    openSelect?: boolean;
    fullHeight?: boolean;
}

@Directive({
    selector: '[appCheckFacility]',
})
export class CheckFacilityDirective extends FcComponent implements CheckFacilityOptions {
    @Input('appCheckFacility') options: CheckFacilityOptions;
    message = true;
    openSelect = true;
    fullHeight = true;

    private _view: 'content' | 'noFac' | null;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private injector: Injector,
                fc: FacilityChooserService) {
        super(fc);
    }

    ngOnChanges() {
        Object.assign(this, this.options);
    }

    compOnFacChange(orgFac: OrganizationFacility) {
        if (orgFac.facility && this._view != 'content') {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
            this._view = 'content';
        } else if (!orgFac.facility && this._view != 'noFac') {
            this.viewContainer.clear();
            if (this.openSelect) setTimeout(() => this.fc.openSelect(), 500);
            if (this.message) {
                const componentRef = createComponent(NoFacilityMessageComponent, this.viewContainer);
                componentRef.instance.fullHeight = this.fullHeight;
                componentRef.changeDetectorRef.detectChanges();
            }
            this._view = 'noFac';
        }
    }
}
